import { STUDENT_LIST_QUERY_KEY } from '@monorepo/onboarding/hooks/useStudentList'
import { useUpdateStudentMutation } from '@monorepo/onboarding/hooks/useUpdateStudentMutation'
import { GetOnboardingBatchRequest } from '@monorepo/onboarding/services/types/commonTypes'
import { GetStudentsResponse, Student } from '@monorepo/onboarding/services/types/student'
import { Button } from '@gravity/button'
import { Callout } from '@gravity/callout'
import { Checkbox } from '@gravity/checkbox'
import { Dialog } from '@gravity/dialog'
import { TextField } from '@gravity/text-field'
import { useToast } from '@gravity/toast'
import { useQueryClient } from '@tanstack/react-query'
import { useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { GET_ONBOARDING_BATCH_QUERY_KEY } from '@monorepo/onboarding/hooks/useFetchOnboardingBatches'

type EditStudentDialogProps = {
  params: GetOnboardingBatchRequest
  selectedStudent: Student
  setSelectedStudent: (student: Student | null) => void
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
`

const TaxIdFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
`

const CheckNoTaxID = styled.div`
  margin-top: 40px;
`

enum StudentErrorCode {
  BIRTH_DATE_EMPTY = 'student.birth_date:required',
  BIRTH_DATE_IN_FUTURE = 'student.birth_date:in_the_future',
  BIRTH_DATE_INVALID = 'student.birth_date:invalid_format',
  NAME_REQUIRED = 'student.name:required',
  TAX_ID_CONFLICT = 'student.tax_id:conflict',
  TAX_ID_INVALID = 'student.tax_id:invalid',
}

const STUDENT_ERROR_MESSAGES: Record<StudentErrorCode, string> = {
  [StudentErrorCode.BIRTH_DATE_EMPTY]: 'Informe a data de nascimento do aluno',
  [StudentErrorCode.BIRTH_DATE_IN_FUTURE]: 'A data de nascimento do aluno não pode estar no futuro',
  [StudentErrorCode.BIRTH_DATE_INVALID]:
    'Informe uma data de nascimento válida. O formato deve ser DD/MM/AAAA.',
  [StudentErrorCode.NAME_REQUIRED]: 'Informe o nome do aluno',
  [StudentErrorCode.TAX_ID_INVALID]: 'Informe um CPF válido',
  [StudentErrorCode.TAX_ID_CONFLICT]: 'Tem mais de um CPF vinculado ao nome',
}

const isError = (
  name: FieldName,
  student: Student,
  originalStudent: Student
): [boolean, string] => {
  const field = `student.${name}`

  const error =
    student.validations.some(i => i.field === field) && student[name] === originalStudent[name]

  const errorMessage =
    STUDENT_ERROR_MESSAGES[
      student.validations.filter(i => i.field === field)[0]?.error_code as StudentErrorCode
    ]

  return [error, errorMessage]
}

type FieldName = keyof Omit<Student, 'validations' | 'is_valid'>

const CustomTextField = ({
  name,
  label,
  student,
  onChange,
  originalStudent,
}: {
  label: string
  name: FieldName
  onChange: (name: string, value: string) => void
  originalStudent: Student
  student: Student
}) => {
  const [error, errorMessage] = isError(name, student, originalStudent)

  return (
    <TextField
      fullWidth
      size={3}
      label={label}
      name={name}
      value={student[name]}
      onChange={e => onChange(e.target.name, e.target.value)}
      errorMessage={errorMessage}
      error={error}
    />
  )
}

export const EditStudentDialog = ({
  selectedStudent,
  setSelectedStudent,
  params,
}: EditStudentDialogProps) => {
  const { batchID } = useParams<{ batchID: string }>()

  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { mutateAsync: executeUpdateStudentMutation, isLoading } = useUpdateStudentMutation()

  const [noTaxID, setNoTaxID] = useState<boolean>(false)

  // Used to determine if any fields have been modified by the user
  const originalStudent = useRef<Student>(selectedStudent)

  const events = useEvents()

  const handleOpenChange = (value: boolean) => {
    events?.closeEditStudentModal()
    if (value === false) {
      setSelectedStudent(null)
    }
  }

  const handleChangeFieldValue = (fieldName: string, value: string) => {
    setSelectedStudent({
      ...selectedStudent,
      [fieldName]: value,
    })
  }

  const handleSubmit = () => {
    events?.confirmEditStudent()
    executeUpdateStudentMutation(
      {
        batchID,
        student: {
          ...selectedStudent,
          tax_id: noTaxID ? '' : selectedStudent.tax_id,
        },
      },
      {
        onSuccess: updatedStudent => {
          if (updatedStudent.is_valid) {
            setSelectedStudent(null)
            queryClient.invalidateQueries([STUDENT_LIST_QUERY_KEY])
            // TODO: consider only invalidating if there's no other invalid students
            queryClient.invalidateQueries([GET_ONBOARDING_BATCH_QUERY_KEY, batchID])

            toast({
              type: 'success',
              title: 'Correção realizada com sucesso',
              description: 'Este aluno saiu da tabela de pendências e está na fila de migração.',
            })

            return
          }

          originalStudent.current = updatedStudent
          setSelectedStudent(updatedStudent)

          queryClient.setQueryData(
            [STUDENT_LIST_QUERY_KEY, params],
            (old: GetStudentsResponse | undefined) => {
              if (old === undefined) {
                return undefined
              }

              const updatedData = old.data.map(student =>
                student.id === updatedStudent.id ? updatedStudent : student
              )

              return {
                ...old,
                data: updatedData,
              }
            }
          )
        },
        onError: error => {
          setSelectedStudent(null)

          toast({
            type: 'error',
            title: 'Erro ao salvar correção.',
            description: 'Tente corrigir novamente',
          })

          console.log(error)
        },
      }
    )
  }

  const [birthDateError, birthDateErrorMessage] = isError(
    'birth_date',
    selectedStudent,
    originalStudent.current
  )

  const [taxIDError, taxIDErrorMessage] = isError(
    'tax_id',
    selectedStudent,
    originalStudent.current
  )

  return (
    <Dialog
      size={3}
      backdrop
      modal
      open={Boolean(selectedStudent)}
      onOpenChange={handleOpenChange}
      title="Corrigir dados de aluno"
      content={
        <ContentWrapper>
          <CustomTextField
            label="Aluno"
            name="name"
            onChange={handleChangeFieldValue}
            student={selectedStudent}
            originalStudent={originalStudent.current}
          />

          <NumberFormat
            style={{ flexGrow: 1, overflow: 'visible' }}
            id="student.birthDate"
            type="text"
            format="##/##/####"
            placeholder="DD/MM/AAAA"
            label="Data de nascimento"
            name="birth_date"
            defaultValue={selectedStudent.birth_date}
            onValueChange={async currentValue => {
              handleChangeFieldValue('birth_date', currentValue.formattedValue)
            }}
            customInput={TextField}
            size={3}
            variant="outlined"
            error={birthDateError}
            errorMessage={birthDateErrorMessage}
          />

          <TaxIdFieldsWrapper>
            <div style={{ flexGrow: 1 }}>
              <NumberFormat
                style={{ flexGrow: 1 }}
                disabled={noTaxID}
                id="student.tax_id"
                type="text"
                format="###.###.###-##"
                label="CPF"
                name="taxID"
                value={selectedStudent.tax_id}
                onValueChange={async currentValue => {
                  handleChangeFieldValue('tax_id', currentValue.value)
                }}
                customInput={TextField}
                size={3}
                variant="outlined"
                error={!noTaxID && taxIDError}
                errorMessage={!noTaxID && taxIDErrorMessage}
              />
            </div>

            <CheckNoTaxID>
              <Checkbox
                size={2}
                text="Aluno não possui CPF"
                onCheckedChange={(checked: boolean) => setNoTaxID(checked)}
              />
            </CheckNoTaxID>
          </TaxIdFieldsWrapper>

          <Callout
            linkLabel="Saiba mais"
            href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/tudo-sobre-edicao-de-dados-cadastrais-de-alunos-"
            text="Ao informar o CPF do aluno, você garante maior segurança aos contratos da escola."
            onLinkClick={events?.moreInfoAboutEditStudent}
          />
        </ContentWrapper>
      }
      actionButton={
        <Button onClick={handleSubmit} loading={isLoading}>
          Salvar
        </Button>
      }
      cancelButton={
        <Button onClick={events?.cancelEditStudent} disabled={isLoading} variant="ghost">
          Cancelar
        </Button>
      }
    />
  )
}
