import { GUARDIAN_LIST_QUERY_KEY } from '@monorepo/onboarding/hooks/useGuardian'
import { useUpdateGuardianMutation } from '@monorepo/onboarding/hooks/useUpdateGuardianMutation'
import { GetOnboardingBatchRequest } from '@monorepo/onboarding/services/types/commonTypes'
import {
  GetGuardiansResponse,
  Guardian,
} from '@monorepo/onboarding/services/types/onboardingGuardian'
import { UFs } from '@/shared/utils'
import { Button } from '@gravity/button'
import { Callout } from '@gravity/callout'
import { Dialog } from '@gravity/dialog'
import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { useToast } from '@gravity/toast'
import { useQueryClient } from '@tanstack/react-query'
import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { GET_ONBOARDING_BATCH_QUERY_KEY } from '@monorepo/onboarding/hooks/useFetchOnboardingBatches'

type EditGuardianDialogProps = {
  params: GetOnboardingBatchRequest
  selectedGuardian: Guardian
  setSelectedGuardian: (guardian: Guardian | null) => void
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  & > * {
    flex: 1 1 0px;
  }
`

enum GuardianErrorCode {
  ADDRESS_CITY_EMPTY = 'guardian.address_city:required',
  ADDRESS_STATE_CODE_EMPTY = 'guardian.address_state_code:required',
  ADDRESS_STATE_CODE_INVALID = 'guardian.address_state_code:invalid',
  ADDRESS_STREET_EMPTY = 'guardian.address_street:required',
  ADDRESS_ZIP_EMPTY = 'guardian.address_zip:required',
  ADDRESS_ZIP_INVALID = 'guardian.address_zip:invalid',
  EMAIL_CONFLICT = 'guardian.email:conflict',
  EMAIL_EMPTY = 'guardian.email:required',
  EMAIL_INVALID = 'guardian.email:invalid',
  NAME_EMPTY = 'guardian.name:required',
  PHONE_NUMBER_EMPTY = 'guardian.phone_number:required',
  PHONE_NUMBER_INVALID = 'guardian.phone_number:invalid',
  TAX_ID_CONFLICT = 'guardian.tax_id:conflict',
  TAX_ID_EMPTY = 'guardian.tax_id:required',
  TAX_ID_INVALID = 'guardian.tax_id:invalid',
}

// TODO: fix those messages
const GUARDIAN_ERROR_MESSAGES: Record<GuardianErrorCode, string> = {
  [GuardianErrorCode.ADDRESS_CITY_EMPTY]: 'Informe um município válido',
  [GuardianErrorCode.ADDRESS_STATE_CODE_EMPTY]: 'Informe UF válido',
  [GuardianErrorCode.ADDRESS_STATE_CODE_INVALID]: 'Informe UF válido',
  [GuardianErrorCode.ADDRESS_STREET_EMPTY]: 'Informe um logradouro válido',
  [GuardianErrorCode.ADDRESS_ZIP_EMPTY]: 'Informe um CEP válido',
  [GuardianErrorCode.ADDRESS_ZIP_INVALID]: 'Informe um CEP válido',
  [GuardianErrorCode.EMAIL_EMPTY]: 'Informe um e-mail válido',
  [GuardianErrorCode.EMAIL_INVALID]: 'Informe um e-mail válido',
  [GuardianErrorCode.EMAIL_CONFLICT]: 'Tem mais de um e-mail vinculado ao nome',
  [GuardianErrorCode.NAME_EMPTY]: 'Informe um nome de responsável válido',
  [GuardianErrorCode.PHONE_NUMBER_EMPTY]: 'Informe um número de celular válido',
  [GuardianErrorCode.PHONE_NUMBER_INVALID]: 'Informe um número de celular válido',
  [GuardianErrorCode.TAX_ID_EMPTY]: 'Informe um CPF válido',
  [GuardianErrorCode.TAX_ID_INVALID]: 'Informe um CPF válido',
  [GuardianErrorCode.TAX_ID_CONFLICT]: 'Tem mais de um CPF vinculado ao nome',
}

const isError = (
  name: FieldName,
  guardian: Guardian,
  originalGuardian: Guardian
): [boolean, string] => {
  const field = `guardian.${name}`

  const error =
    guardian.validations.some(i => i.field === field) && guardian[name] === originalGuardian[name]

  const errorMessage =
    GUARDIAN_ERROR_MESSAGES[
      guardian.validations.filter(i => i.field === field)[0]?.error_code as GuardianErrorCode
    ]

  return [error, errorMessage]
}

type FieldName = keyof Omit<Guardian, 'validations' | 'is_valid'>

const CustomTextField = ({
  name,
  label,
  guardian,
  onChange,
  originalGuardian,
}: {
  guardian: Guardian
  label: string
  name: FieldName
  onChange: (name: string, value: string) => void
  originalGuardian: Guardian
}) => {
  const [error, errorMessage] = isError(name, guardian, originalGuardian)

  return (
    <TextField
      fullWidth
      size={3}
      label={label}
      name={name}
      value={guardian[name]}
      onChange={e => onChange(e.target.name, e.target.value)}
      errorMessage={errorMessage}
      error={error}
    />
  )
}

export const EditGuardianDialog = ({
  selectedGuardian,
  setSelectedGuardian,
  params,
}: EditGuardianDialogProps) => {
  const { batchID } = useParams<{ batchID: string }>()

  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { mutateAsync: executeUpdateGuardianMutation, isLoading } = useUpdateGuardianMutation()

  // Used to determine if any fields have been modified by the user
  const originalGuardian = useRef<Guardian>(selectedGuardian)

  const events = useEvents()

  const handleOpenChange = (value: boolean) => {
    events?.closeEditGuardianModal()
    if (value === false) {
      setSelectedGuardian(null)
    }
  }

  const handleChangeFieldValue = (fieldName: string, value: string) => {
    setSelectedGuardian({
      ...selectedGuardian,
      [fieldName]: value,
    })
  }

  const handleSubmit = () => {
    events?.confirmEditGuardian()
    executeUpdateGuardianMutation(
      {
        batchID,
        guardian: {
          ...selectedGuardian,
        },
      },
      {
        onSuccess: updatedGuardian => {
          if (updatedGuardian.is_valid) {
            setSelectedGuardian(null)
            queryClient.invalidateQueries([GUARDIAN_LIST_QUERY_KEY])
            queryClient.invalidateQueries([GET_ONBOARDING_BATCH_QUERY_KEY, batchID])

            toast({
              type: 'success',
              title: 'Correção realizada com sucesso',
              description:
                'Este responsável saiu da tabela de pendências e está na fila de migração.',
            })

            return
          }

          originalGuardian.current = updatedGuardian
          setSelectedGuardian(updatedGuardian)

          queryClient.setQueryData(
            [GUARDIAN_LIST_QUERY_KEY, params],
            (old: GetGuardiansResponse | undefined) => {
              if (old === undefined) {
                return undefined
              }

              const updatedData = old.data.map(guardian =>
                guardian.id === updatedGuardian.id ? updatedGuardian : guardian
              )

              return {
                ...old,
                data: updatedData,
              }
            }
          )
        },
        onError: error => {
          setSelectedGuardian(null)

          toast({
            type: 'error',
            title: 'Erro ao salvar correção.',
            description: 'Tente corrigir novamente',
          })

          console.log(error)
        },
      }
    )
  }

  // TODO: Check if there is a state code validation
  const [stateCodeError, stateCodeErrorMessage] = isError(
    'address_state_code',
    selectedGuardian,
    originalGuardian.current
  )

  return (
    <Dialog
      size={3}
      backdrop
      modal
      open={Boolean(selectedGuardian)}
      onOpenChange={handleOpenChange}
      title="Editar dados de responsável financeiro"
      content={
        <ContentWrapper>
          <Flex>
            <CustomTextField
              label="Responsável"
              name="name"
              onChange={handleChangeFieldValue}
              guardian={selectedGuardian}
              originalGuardian={originalGuardian.current}
            />

            <CustomTextField
              label="CPF"
              name="tax_id"
              onChange={handleChangeFieldValue}
              guardian={selectedGuardian}
              originalGuardian={originalGuardian.current}
            />
          </Flex>

          <Flex>
            <CustomTextField
              label="E-mail"
              name="email"
              onChange={handleChangeFieldValue}
              guardian={selectedGuardian}
              originalGuardian={originalGuardian.current}
            />

            <CustomTextField
              label="Celular"
              name="phone_number"
              onChange={handleChangeFieldValue}
              guardian={selectedGuardian}
              originalGuardian={originalGuardian.current}
            />
          </Flex>

          <Flex>
            <CustomTextField
              label="Logradouro"
              name="address_street"
              onChange={handleChangeFieldValue}
              guardian={selectedGuardian}
              originalGuardian={originalGuardian.current}
            />

            <CustomTextField
              label="Número"
              name="address_number"
              onChange={handleChangeFieldValue}
              guardian={selectedGuardian}
              originalGuardian={originalGuardian.current}
            />

            <CustomTextField
              label="Complemento"
              name="address_additional_information"
              onChange={handleChangeFieldValue}
              guardian={selectedGuardian}
              originalGuardian={originalGuardian.current}
            />
          </Flex>

          <Flex>
            <CustomTextField
              label="Município"
              name="address_city"
              onChange={handleChangeFieldValue}
              guardian={selectedGuardian}
              originalGuardian={originalGuardian.current}
            />

            <CustomTextField
              label="CEP"
              name="address_zip"
              onChange={handleChangeFieldValue}
              guardian={selectedGuardian}
              originalGuardian={originalGuardian.current}
            />

            <Select
              size={3}
              variant="surface"
              label="UF"
              defaultValue={selectedGuardian.address_state_code}
              options={UFs.map(UF => ({
                label: UF,
                value: UF,
              }))}
              onValueChange={value => handleChangeFieldValue('address_state_code', value)}
              hasError={stateCodeError}
              helperText={stateCodeErrorMessage}
            />
          </Flex>
          <Callout
            linkLabel="Saiba mais"
            href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/tudo-sobre-edicao-de-dados-cadastrais-de-alunos-"
            text="Todos os dados cadastrais acima são obrigatórios para a migração de contratos para a Plataforma isaac."
            onLinkClick={events?.moreInfoAboutEditGuardian}
          />
        </ContentWrapper>
      }
      actionButton={
        <Button onClick={handleSubmit} loading={isLoading}>
          Salvar
        </Button>
      }
      cancelButton={
        <Button onClick={events?.cancelEditGuardian} disabled={isLoading} variant="ghost">
          Cancelar
        </Button>
      }
    />
  )
}
